.aboutMeBody {
    text-align: left;
    margin: 0 10vw 0 10vw;
}

.socialLinks {
    display: flex;
    justify-content: center;
    gap: 15px;
    margin-top: 3vh;
    margin-bottom: 3vh;
}

/* Styles for the image bar */
.imageBar {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
}

/* Styles for the white image background */
.imageBackground {
    height: 100px;
    width: 100px;
    background: white;
    border-radius: 10px;
    margin: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
}

/* Styles for the images inside the image bar */
.imageBar img {
    height: 90px;
    width: auto;
}

