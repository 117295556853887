/* Encapsulates the complete start and end of timeline */
.design-section {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    max-width: 800px;
    margin: auto;
}

/* Styles for all the timeline elements */
.timeline {
    display: grid;
    grid-template-columns: 1fr 3px 1fr;
}

/* Styles for the middle line of the timeline */
.timeline-middle {
    position: relative;
    background-image: linear-gradient(45deg, white, var(--pink2), var(--pink1));
    width: 3px;
    height: 100%;
}

/* Styles for the content within the timeline */
.timeline-content {
    padding: 10px 15px;
    border: 3px solid white;
    border-radius: 10px;
    color: white;
    transition: 0.4s ease;
    overflow-wrap: break-word !important;
    margin: 1rem;
    margin-bottom: 20px;
    opacity: 0;
    animation: fade-in linear forwards;
    animation-timeline: view();
    animation-range: 10vh 20vh;
}

/* Styles for the components within the timeline */
.timeline-component {
    margin: 0px 0px 20px 0px;
}

/* Styles for the circle element of the timeline */
.timeline-circle {
    position: relative;
    top: 0;
    left: 50%;
    width: 15px;
    height: 15px;
    border-radius: 50%;
    background-image: linear-gradient(45deg, white, var(--pink2), var(--pink1));
    transform: translateX(-50%);
}

/* Styles for the date element of the timeline */
.timeline-date {
    display: flex;
    align-items: center;
    padding: 15px;
    opacity: 0;
    animation: fade-in linear forwards;
    animation-timeline: view();
    animation-range: 10vh 20vh;
}

/* Keyframes for the fade-in animation */
@keyframes fade-in {
    to {
        opacity: 1;
    }
}
