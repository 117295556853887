/* Importing CSS files */
@import url('landing.css');
@import url('projects.css');
@import url('about-me.css');
@import url('timeline.css');
@import url('footer.css');
@import url('project-page.css');
@import url('contact-me.css');

/* CSS variables */
:root {
    --background: #171717;
    --pink1: #d735ff;
    --pink2: #9300b8;
    --pink3: #a371b0;
}

/* Hide scroll bar because I don't want it */
html {
    overflow: scroll;
    overflow-x: hidden;
}
::-webkit-scrollbar {
    width: 0; 
    background: transparent;
}
::-webkit-scrollbar-thumb {
    background: #FF0000;
}

/* Styles for the body */
body {
    font-family: "Outfit", sans-serif;
    font-weight: 400;
    font-style: normal;
    background-color: var(--background);
    background: var(--background);
    background: linear-gradient(162deg, rgba(14, 12, 14, 1) 0%, rgba(21, 0, 42, 1) 48%, rgba(0, 0, 24, 1) 100%);
    background-repeat: no-repeat;
    min-height: 100vh;
    color: white;
    font-size: calc(0.3vw + 1.5vh);
}

/* CSS class to trigger fade-in animation used by developer word */
.fade-in {
    animation: fadeInAnimation 1s ease-in-out 1.5s forwards;
}

/* CSS class to trigger fade-in animation used by arrow */
.fade-in-2 {
    animation: fadeInAnimation 1s ease-in-out 2.3s forwards;
}

/* Keyframes for fade-in animation */
@keyframes fadeInAnimation {
    from {
        opacity: 0;
    }

    to {
        opacity: 1;
    }
}

/* Style for links */
a {
    color: var(--pink1);
    text-decoration: none;
    transition: color 0.2s ease;
}

/* Style for links on hover */
a:hover {
    color: var(--pink2);
    background-color: transparent;
    text-decoration: none;
    transition: color 0.2s ease;
}

/* Styles for the section head */
.section-head {
    font-size: calc(1vw + 3vh);
    font-weight: normal;
    text-align: center;
}

/* Styles for the underline of sections */
.square {
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 5px;
    height: 0.4vh;
    width: calc(12vw + 30vh);
    background-color: white;
    margin-left: auto;
    margin-right: auto;
    margin-top: 1vh;
    margin-bottom: 5vh;
}
