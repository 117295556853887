/* Styles for the footer */
footer {
    display: flex;
    justify-content: center; 
    align-items: center; 
    margin-left: auto; 
    margin-right: auto; 
    text-align: center; 
    margin-bottom: 2vh; 
    display: block;
}