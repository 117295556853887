#contact-me {
    max-width: 600px;
    margin: 0 auto 0;
}

.social-links {
    display: flex;
    justify-content: center;
    margin-bottom: 2em;
}

.social-image {
    height: 10vh;
    width: 10vh;
    padding: 10px;
    border-radius: 15%;
    transition: transform 0.3s ease;
}

.social-image:hover {
    transform: scale(1.1);
}

.email-form-container {
    padding: 2rem;
    border-radius: 8px;
}

.email-form {
    display: flex;
    flex-direction: column;
    gap: 1rem;
}

.form-row {
    display: flex;
    gap: 2rem;
}

.form-row > div {
    flex: 1;
}

label {
    display: block;
    margin-bottom: 0.5rem;
    font-weight: bold;
}

input, textarea {
    width: 100%;
    padding: 0.5rem;
    border: 1px solid #ced4da;
    border-radius: 4px;
    font-size: 1rem;
}

textarea {
    resize: vertical;
}

.button-container {
    display: flex;
    justify-content: center;
    margin-top: 1rem;
}

.submit-button {
    background-color: var(--pink1);
    color: white;
    border: none;
    padding: 0.75rem 1.5rem;
    font-size: 1rem;
    border-radius: 4px;
    cursor: pointer;
    transition: background-color 0.3s ease;
}

.submit-button:hover {
    background-color: var(--pink2);
}

.thank-you-message {
    text-align: center;
}

@media (max-width: 768px) {
    .form-row {
        flex-direction: column;
        gap: 1rem;
    }

    .social-image {
        height: 8vh;
        width: 8vh;
    }

    .email-form-container {
        padding: 1rem;
    }
}